<template>
   <div>
      <Slider />
      <Story />
      <Impact />
      <Team />

   </div>
</template>

<script>
export default {
    name: 'About',
    data () {
       return {
            page: 'about',
       };
    },
    components: {
      Slider: () => import('./slider'),
      Story: () => import('./story'),
      Impact: () => import('./impact'),
      Team: () => import('./team'),

      

    },

}
</script>

<style>

</style>